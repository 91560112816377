import React, { Component } from "react"
import Layout from "../../components/layout/Layout"
import PageHelmet from "../../components/Helmet"
import BGImage from "gatsby-background-image"
import Img from "gatsby-image"

class ServicesWeb extends Component {
    render() {
        return(
            <>
                <PageHelmet pageTitle='Services > Web Development' />
                <Layout>
                    {/* Start Banner Area */}
                    <BGImage
                        fluid={this.props.data.headerBackgroundImage.childImageSharp.fluid}>
                        <div className="rn-page-title-area pt--120 pb--190"  data-black-overlay="8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="rn-page-title text-center pt--100">
                                            <h2 className="title theme-gradient">WEB DEVELOPMENT</h2>
                                            <p>Beautiful, Blazingly Fast, and Built to Scale</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BGImage>
                    {/* End Banner Area */}

                    {/* Start Page Wrapper */}
                    <div className="rn-service-details ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-details-inner">
                                        <div className="inner">
                                            {/* Start Single Area */}
                                            <div className="row sercice-details-content pb--80 align-items-center">
                                                <div className="col-lg-6 col-12">
                                                    <div className="thumb">
                                                        <Img className="w-100" fluid={this.props.data.bodyImage1.childImageSharp.fluid} alt="Web Code Image"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <p>The web browser has become the swiss army knive of the modern business. Applications built on web-based technologies allow companies to connect with their customers, manage their operations, and much more from anywhere in the world at any time of the day.</p>
                                                        <p>Whether you're looking to build a simple website to market your business or a highly customized bespoke inventory management system for your warehouse, we can help you select the technologies that meet the needs of <i>your</i> business.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}

                                            {/* Start Single Area */}
                                            <div className="row sercice-details-content pb--80 align-items-center">
                                                <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h4 className="title">Websites</h4>
                                                        <p>If you're looking to build a public website for your business, the options seem endless. Do you use an inexpensive site generator service? Do you need WordPress or onther server-side content management system? Is a high-performance "static" site right for you?</p>
                                                        <p>Regardless of the business you're in, having a website that performs well on both desktops and mobile devices is critical to converting visitors into customers. We work with our customers to define their goals, help them select the right technologies to accomplish their goals, and then deliver a beautiful professional site that leaves their competition in the dust.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                    <div className="thumb position-relative">
                                                    <Img className="w-100" fluid={this.props.data.bodyImage2.childImageSharp.fluid} alt="Website Sketches Image"/>
                                                        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} /> */}
                                                        {/* <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}

                                            {/* Start Single Area */}
                                            <div className="row sercice-details-content align-items-center">
                                                <div className="col-lg-6 col-12">
                                                    <div className="thumb">
                                                    <Img className="w-100" fluid={this.props.data.bodyImage3.childImageSharp.fluid} alt="Web Application on a Laptop Image"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h4 className="title">Web Applications</h4>
                                                        <p>Despite using some of the same technologies, web-based applications behave a little differently than your average website. Web apps usually help manage some aspect of your business and are typically used by your employees as part of their daily work.</p>
                                                        <p>Because daily business operations rely on systems like this, it's imperative that they are easy to use, respond quickly to users, and above all else do not fail. When developing web-based applications, you want to be sure that your team has experience building highly scalable, fault tolerant, distributed applications using the latest in cloud technologies.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Page Wrapper */}                    
                </Layout>
            </>
        )
    }
}

export default ServicesWeb

export const query = graphql`
    query {
        headerBackgroundImage: file(relativePath: { eq: "services-web-banner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bodyImage1: file(relativePath: { eq: "services-web-sites.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bodyImage2: file(relativePath: { eq: "services-web-sites2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bodyImage3: file(relativePath: { eq: "services-web-apps.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }                
    }
`